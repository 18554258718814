import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Img from 'gatsby-image';
import Container from '../components/Container'
import Article from '../components/Article'
import styled from '@emotion/styled';
import { rhythm, clearFix } from '../styles/util';
import { MQ } from '../styles/constants';


const RightImageWrap = styled.div`
  width: 45%;
  float: right;
  clear: both;
  margin: 0 0 ${rhythm(1)} ${rhythm(1)};

  ${MQ.MS} {
    width: auto;
    float: none;
    margin: 0;
  }
`

const FaqPage = ({data}) => {
  const {frontmatter: {title, description}, html} = data.file.childMarkdownRemark;
  const faqs = data.faqs.edges.map(e => e.node.childMarkdownRemark);

  return (
    <Layout>
      <SEO title={title} description={description} />

      <Container>
        <Article css={clearFix}>
          <h1 id="faq-top">{title}</h1>

          <ul>
            {faqs.map((faq, i) => 
              <li key={faq.id}>
                <a href={`#faq${i+1}`}>{faq.frontmatter.title}</a>
              </li>)}
          </ul>

          {faqs.map(({id, frontmatter: {title, image, imageAlt}, html}, i) => 
            <Article id={`faq${i+1}`} key={id} padded={false}>
              <h2>{title}</h2>
              {image && 
                <RightImageWrap>
                  <Img fluid={image.childImageSharp.fluid} alt={imageAlt || title} />
                </RightImageWrap>}
              <div dangerouslySetInnerHTML={{__html: html}} />
              <a href="#faq-top">Back to top ↑</a>
            </Article>)}
        </Article>
      </Container>
      
    </Layout>
  )
}

export default FaqPage;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "pages/faqs.md"}) {
      childMarkdownRemark {
        frontmatter { title description }
        html
      }
    }

    faqs: allFile(filter: {relativeDirectory: {eq: "faqs"}}, sort: {fields: childMarkdownRemark___frontmatter___order}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 512) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imageAlt
            }
            html
          }
        }
      }
    }
  }
`;
