import styled from "@emotion/styled"
import {rhythm} from '../styles/util'
import {colors} from '../styles/constants'

const Article = styled.article`
  padding: ${p => p.padded ? `0 ${rhythm(1)}` : 0};
  position: relative;
`
Article.defaultProps = { padded: true };

export default Article;